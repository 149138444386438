import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A Férfi Borbély Céhről: Rólunk | A Férfi Borbély Céhről
			</title>
			<meta name={"description"} content={"Lépjen be a The Gents' Barber Guild világába, ahol minden egyes nyírás és borotválkozás nem csupán egy ápolási rutin"} />
			<meta property={"og:title"} content={"A Férfi Borbély Céhről: Rólunk | A Férfi Borbély Céhről"} />
			<meta property={"og:description"} content={"Lépjen be a The Gents' Barber Guild világába, ahol minden egyes nyírás és borotválkozás nem csupán egy ápolási rutin"} />
			<meta property={"og:image"} content={"https://unitedmastenga.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://unitedmastenga.com/img/595777.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://unitedmastenga.com/img/595777.png"} />
			<meta name={"msapplication-TileImage"} content={"https://unitedmastenga.com/img/595777.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Rólunk
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Lépjen be a The Gents' Barber Guild világába, ahol minden egyes nyírás és borotválkozás nem csupán egy ápolási rutin, hanem egy hagyományokkal és modern kifinomultsággal átszőtt rituálé.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://unitedmastenga.com/img/4.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://unitedmastenga.com/img/5.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://unitedmastenga.com/img/6.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://unitedmastenga.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Lényegünk
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					A The Gents' Barber Guildnél a borbélyművészet időtlen művészetét ötvözzük a kortárs férfi stílus lüktetésével. Történetünk nem régi mesékben van rögzítve - azt minden nap a tükörben bólogató ügyfeleink elégedett bólogatásai írják. A kivételes szakértelem, az odaadó kiszolgálás és az ápolás iránti szenvedély alapelveire alapozva többet építettünk, mint egy egyszerű fodrászüzletet - a modern úriemberek menedékét teremtettük meg.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Több mint egy hajvágás
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Céhünk tanúbizonyságot tesz arról a meggyőződésről, hogy az ápolás elengedhetetlen része a férfi életének. Ez a személyes stílus kifejezése, az önbizalom erősítése, és egy módja annak, hogy egy pillanatnyi kikapcsolódást nyerjünk a rohanó világban.
					<br />
					Képzett kézművesek: Fodrászaink nem csak stylistok - ők a férfiak ápolásának művészetére szakosodott mesteremberek.
					<br />
					Személyre szabott élmények: Megértjük, hogy minden férfi haja és szakálla egyedi.{" "}
					<br />
					Szolgáltatásainkat az egyéni preferenciákhoz és igényekhez igazítjuk.
					<br />
					Egy hely a kikapcsolódásra: A The Gents' Barber Guild egy olyan menedékhely, ahol a légkör ugyanolyan hívogató, mint a kifogástalan kiszolgálás.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://unitedmastenga.com/img/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://unitedmastenga.com/img/9.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Csatlakozzon történetünkhöz
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Meghívjuk Önt, hogy legyen részese a The Gents' Barber Guild történetének - egy olyan történetnek, ahol a hagyomány találkozik a trendekkel, a precizitás a művészettel, és minden látogatás egy emlékezetes élmény.
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});